import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Two new animations of continental movements are approaching completeness. `}<strong parentName="p">{`South
Atlantic Ocean`}</strong></p>
    <p>{`The first of these is an animation of the development of the South Atlantic
Ocean to accompany an  invited paper in the `}<strong parentName="p">{`Journal of African Earth
Sciences.`}</strong>{` The animation may be viewed and  downloaded here but is subject to revision
during the review process. `}</p>
    <h1>{`Bouvet Mantle Plume`}</h1>
    <p>{`The second animation attempts to describe dynamically the evolution of the ocean
around  southern Africa as a result of the activity of the Bouvet mantle plume.
A provisional animation is  available at the link `}<a parentName="p" {...{
        "href": "/"
      }}>{`here`}</a>{`.  `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      